import React, { useEffect } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { useStore } from "store";

type PageMetaProps = {};

const PageMeta: React.FC<PageMetaProps> = (props) => {
  const location = useLocation();
  const account = useStore.useSelectedAccount?.();

  const routeMeta = React.useMemo(() => {
    if (matchPath("/:accountSlug/dataset-design/*", location.pathname))
      return {
        title: `Dataset Designs`,
      };

    if (matchPath("/:accountSlug/fusion-action-module/*", location.pathname))
      return {
        title: `Fusion Actions`,
      };

    if (matchPath("/:accountSlug/datacard-design-module/*", location.pathname))
      return {
        title: `Datacard Designs`,
      };

    if (matchPath("/:accountSlug/fusion/*", location.pathname))
      return {
        title: `Fusions`,
      };

    if (
      matchPath("/:accountSlug/gui-module/:slug/dashboard/*", location.pathname)
    )
      return {
        title: `Gui Dashboard`,
      };

    if (matchPath("/:accountSlug/gui-module/*", location.pathname))
      return {
        title: `GUIs`,
      };

    if (matchPath("/:accountSlug/import-module/*", location.pathname))
      return {
        title: `Imports`,
      };

    if (
      matchPath(
        "/:accountSlug/vector-knowledgebase-module/*",
        location.pathname
      )
    )
      return {
        title: `Vector KnowledgeBase`,
      };

    if (
      matchPath(
        "/:accountSlug/finetune-knowledgebase-module/*",
        location.pathname
      )
    )
      return {
        title: `Finetune KnowledgeBase`,
      };

    if (matchPath("/:accountSlug/presentation/*", location.pathname))
      return {
        title: `Presentations`,
      };

    if (matchPath("/:accountSlug/icons/*", location.pathname))
      return {
        title: `Icons`,
      };

    if (matchPath("/:accountSlug/account-user-module/*", location.pathname))
      return {
        title: `Account Users`,
      };

    if (matchPath("/:accountSlug/settings/*", location.pathname))
      return {
        title: `Sylar`,
      };
  }, [location.pathname, account]);

  useEffect(() => {
    document.title = routeMeta?.title || `${account?.name || ""}`;
  }, [account?.name, routeMeta]);

  return <></>;
};

export default PageMeta;
